import { render, staticRenderFns } from "./BatchAction.vue?vue&type=template&id=70946edc&scoped=true&"
import script from "./BatchAction.vue?vue&type=script&lang=js&"
export * from "./BatchAction.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70946edc",
  null
  
)

export default component.exports