<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading">
            <h5 class="border-bottom pb-2 text-primary">{{ action == 'delete' ? "Delete applications" : (action == 'pdf'
                ? 'Generate PDF reports' : (action == 'archive' ? 'Archive applications' : (action == 'hold' ? 'Put applications on hold' : (action == 'csv' ? 'Export to CSV' : 'Request error'))))}}</h5>
            <form autocomplete="off">
                <div v-if="action == 'delete'">
                    <p>This action will permanantly delete these {{ applicationCount }} applications.</p>
                    <p><strong>This action is non-reversible</strong></p>
                </div>
                <div v-else-if="action == 'archive'">
                    <p>This action will archive these {{ applicationCount }} applications.</p>
                    <p>Please select whether you would like to immediately archive these applications, or at a later date</p>
                    <p>
                        <b-form-radio-group
                                stacked
                                id="checkbox-group-1"
                                v-model="archiveType"
                                :options="archiveOptions"
                                name="flavour-1"
                        ></b-form-radio-group>
                    </p>
                    <p v-if="archiveType == 'archive_at'">
                        <b-form-group
                                label-size="sm"
                                label="Archive on this date:">
                            <masked-input class="form-control"
                                          v-model="archiveDate"
                                          mask="11/11/1111"
                                          placeholder="dd/mm/yyyy"
                            />
                        </b-form-group>
                    </p>
                </div>
                <div v-else-if="action == 'pdf'">
                    <p>This action will generate the requested {{ applicationCount }} application PDFs.</p>
                    <p>You will be emailed
                        at {{ this.$store.state.user.email }} with a secure link to download the results.</p>
                </div>

                <div v-else-if="action == 'csv'">
                    <template v-if="applicationCount > downloadThreshold">
                        <p>As you are trying to download over {{ downloadThreshold }} applications at once we will generate a CSV export of the requested {{ applicationCount }} applications and email you once it's done.</p>
                        <p>You will be emailed
                            at {{ this.$store.state.user.email }} with a secure link to download the results.</p>
                    </template>
                    <template v-else>
                        <p>You are downloading {{ applicationCount }} applications to CSV.</p>
                        <p>This document will contain sensitive candidate information, please ensure appropriate measures are taken to keep it secure.</p>
                    </template>

                </div>

                <div v-else-if="action == 'hold'">
                    <p>This action will generate a put on hold the requested {{ applicationCount }} applications.</p>
                </div>

                <error-display v-model="errors" ident="general"></error-display>
                <div class="text-right">
                    <b-button @click="saveModel" variant="success">Continue</b-button>
                </div>
            </form>

        </div>
    </div>
</template>
<script>
    import {containsErrors, statusIsComplete} from "../../modules/helpers/helpers";
    import {getResource, saveResource} from "../../modules/api/methods";
    import {
        applicationBatch,
        applicationsCount,
        applicationsExcel,
        serialize,
        userSingleUse
    } from "../../modules/api/endpoints";
    import MaskedInput from "vue-masked-input/src/MaskedInput";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import * as moment from "moment";

    export default {
        name: "BatchAction",
        components: {ErrorDisplay, MaskedInput},
        props: {
            inputData : null,
            applicationId: null,
        },
        data: function () {
            return {
                downloadThreshold : 100,
                filters: null,
                action: null,
                errors : {},
                isLoading: false,
                applicationCount: null,
                archiveAt: false,
                archiveType : 'archive',
                archiveDate : null,
                archiveOptions : [
                    { text: 'Archive now', value: 'archive' },
                    { text: 'Archive two years from now', value: 'archive_two_years' },
                    { text: 'Archive at a specified date', value: 'archive_at' },
                ]
            }
        },
        created() {
            var json = JSON.parse(this.inputData);
            if(json.filters !== undefined) {
                this.filters = json.filters;
            }
            if(json.action !== undefined) {
                this.action = json.action;
            }
            this.loadModel()
        },
        methods: {
            loadModel() {
                this.isLoading = true;
                console.log("FILTERS ARE",this.filters);
                saveResource(applicationsCount, this.filters).then((resp) => {
                    this.applicationCount = resp.data.success;

                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            downloadExcel() {
                console.log("DOWNLOADING EXCEL");
                var postData = this.filters;
                this.isLoading = true;
                getResource(userSingleUse(this.$store.getters.isAdmin)).then((resp) => {

                    postData.token = resp.data.success.token;

                    var query = serialize(postData, null);
                    if (postData.uploaded_at != null) {
                        if (new moment(postData.uploaded_at.startDate).isValid()) {
                            query = query + "&" + encodeURIComponent('uploaded_at[startDate]') + "=" + new moment(postData.uploaded_at.startDate).format("YYYY-MM-DD");
                        }
                        if (new moment(postData.uploaded_at.endDate).isValid()) {
                            query = query + "&" + encodeURIComponent('uploaded_at[endDate]') + "=" + new moment(postData.uploaded_at.endDate).format("YYYY-MM-DD");
                        }
                    }
                    console.log("QUERY IS", query);

                    window.open(applicationsExcel(query), '_blank');


                    this.isLoading = false;
                    if(this.applicationId != null) {
                        this.$router.push({'name': 'applicationDetail', params: {applicationId: this.applicationId, data_updated: true}}).catch(error => {});
                    } else {
                        this.$router.push({'name': 'applications', params: {data_updated: true}}).catch(error => {});
                    }
                }).catch((ex) => {
                    console.log(ex);
                    if (containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            },
            saveModel() {
                console.log("ACTION IS "+this.action+" COUNT IS "+this.applicationCount);
                if(this.action == 'csv' && this.applicationCount <= this.downloadThreshold) {
                    console.log("ACTION IS "+this.action+" COUNT IS "+this.applicationCount);
                    this.downloadExcel();
                    return;
                }
                this.isLoading = true;
                this.errors = {};
                var postData = this.filters;
                var action = this.action;
                if(this.action == 'archive') {
                    action = this.archiveType;
                    if(this.archiveType == 'archive_at') {
                        postData.archive_date = this.archiveDate
                    }
                }
                saveResource(applicationBatch(action),postData).then((resp) => {
                    this.isLoading = false;
                    if(this.applicationId != null) {
                        this.$router.push({'name': 'applicationDetail', params: {applicationId: this.applicationId, data_updated: true}}).catch(error => {});
                    } else {
                        this.$router.push({'name': 'applications', params: {data_updated: true}}).catch(error => {});
                    }
                    if(this.action != 'archive') {
                        this.$root.$children[0].showToastMessage("Now processing", "Your download is now being processed and you will be emailed once it's complete.", "success");
                    }
                }).catch((err) => {
                    if (containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            }
        }

    }
</script>

<style scoped>

</style>
